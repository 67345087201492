import { UserManagerSettings } from "oidc-client-ts"
export const AuthConfig: UserManagerSettings = {
    authority: "https://filesserver.dev-lplusltd.com",
    client_id: "userManagerL",
    client_secret: '901564A5-E7FE-42CB-B10D-61EF6A8F3654',
    redirect_uri: "https://filesuser.dev-lplusltd.com",
    response_type: "code",
    scope: "openid profile roles",
    post_logout_redirect_uri: "https://filesuser.dev-lplusltd.com/logout",
    response_mode: "query"
    //authority: "https://localhost:7157",
    //client_id: "userManagerL",
    //client_secret: '901564A5-E7FE-42CB-B10D-61EF6A8F3654',
    //redirect_uri: "https://localhost:44432",
    //response_type: "code",
    //scope: "openid profile roles",
    //post_logout_redirect_uri: "https://localhost:44432/logout",
    //response_mode:"query"
    //authority: "https://authserver.billcrm.org",
    //client_id: "userManagerP",
    //client_secret: '901564A5-E7FE-42CB-B10D-61EF6A8F3654',
    //redirect_uri: "https://unuser.dev-lplusltd.com",
    //response_type: "code",
    //scope: "openid profile roles",
    //post_logout_redirect_uri: "https://unuser.dev-lplusltd.com/logout",
    //response_mode:"query"

  };