import { useAuth } from "react-oidc-context";
import { Outlet } from "react-router-dom";

export function Private() {

    const auth = useAuth();

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }


    if (!auth.isAuthenticated) {
        auth.signinRedirect();
        return (
            <div>Redirecting to Auth server</div>
        )
    }

    return (
        <Outlet />
    )
}