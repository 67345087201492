import React from "react";
import logo from "./assets/logo.png";
import "./App.scss";
import Header from "../shared/header/header";
import { Outlet } from "react-router-dom";
import Sidebar from "shared/sidebar/Sidebar";

function App() {
  return (
    <div className="wrapper">
      <Sidebar></Sidebar>
      <Header></Header>
      <div className="page-wrapper">
        <div className="page-content">
          <Outlet />
        </div>
      </div>
      <footer className="page-footer"></footer>
    </div>
  );
}

export default App;
