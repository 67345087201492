import apiHandler from "core/communication/apiHandler";
import { Role, User } from "../models/UserModel";

export function getUsers(token?: string) {
  return apiHandler(token).get<Array<User>>("/api/users");
}

export function getRoles(token?: string) {
  return apiHandler(token).get<Array<Role>>("/api/roles");
}

export function saveUser(token?: string, body?: User) {
  return apiHandler(token).post<User>("/api/users", body);
}

export function updateUser(token?: string, body?: User) {
  return apiHandler(token).put<User>("/api/users", body);
}

export function lockOutUser(token?: string, id?: string) {
  return apiHandler(token).put<User>(`/api/users/lockout/${id}`, {});
}

export function unlockUser(token?: string, id?: string) {
  return apiHandler(token).put<User>(`/api/users/unlock/${id}`, {});
}

export function addRole(token?: string, role?: Role) {
  return apiHandler(token).post("/api/roles", role);
}

export function deleteRole(token?: string, id?: string) {
  return apiHandler(token).delete(`/api/roles/${id}`);
}
