import { Application } from "app/models/ApplicationModel";
import apiHandler from "core/communication/apiHandler";

export function getApplications(token?: string) {
  return apiHandler(token).get<Array<Application>>("/api/client");
}

export function addApplication(token?: string, body?: Application) {
  return apiHandler(token).post<Application>("/api/client", body);
}

export function updateApplication(token?: string, body?: Application) {
  return apiHandler(token).put<Application>("/api/client", body);
}

export function deleteApplication(token?: string, clientId?: string) {
  return apiHandler(token).delete("/api/client/" + clientId);
}
