import React, { useEffect, useState } from "react";
import logo from "assets/logo_min.png";
import "./Sidebar.scss";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth } from "react-oidc-context";

function Sidebar() {
  var auth = useAuth();

  return (
    <div className="sidebar-wrapper" data-simplebar="true">
      <div className="sidebar-header">
        <div>
          <img src={logo} className="logo-icon" alt="logo icon" />
        </div>
        <div>
                  <h4 className="logo-text">LPLUS</h4>
        </div>
        <div className="toggle-icon ms-auto">
          <i className="bx bx-arrow-to-left"></i>
        </div>
      </div>
      <ul className={"metismenu" + (auth.isAuthenticated ? ' show-menu' : ' hide-menu')} id="menu">
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "mm-active" : "")}
          >
            <div className="parent-icon">
              <i className="bx bx-grid-alt"></i>
            </div>
            <div className="menu-title">Application</div>
          </NavLink>
          <NavLink
            to="users"
            className={({ isActive }) => (isActive ? "mm-active" : "")}
          >
            <div className="parent-icon">
              <i className="bx bx-user-pin"></i>
            </div>
            <div className="menu-title">Users</div>
          </NavLink>
          <NavLink
            to="roles"
            className={({ isActive }) => (isActive ? "mm-active" : "")}
          >
            <div className="parent-icon">
              <i className="bx bxs-user-detail"></i>
            </div>
            <div className="menu-title">Roles</div>
          </NavLink>
        </ul>
    </div>
  );
}

export default Sidebar;
