import { Application } from "app/models/ApplicationModel";
import { deleteApplication, getApplications } from "app/services/ApplicationsAPI";
import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import NewApplicationForm from "./NewApplicationForm/NewApplicationForm";

export default function Applications() {
  const auth = useAuth();

  const [apps, setApps] = React.useState<Array<Application>>([]);
  const [selectedApp, setSelectedApp] = React.useState<Application>();

  useEffect(() => {
    refreshApps();
  }, []);

  const refreshApps = () => {
    getApplications(auth.user?.access_token).then(res => {
        setApps(res.data);
    })
  }

  const handleSaveApp = () => {
    refreshApps();
  };

  const deleteApp = (clientId: string) => {
    deleteApplication(auth.user?.access_token, clientId).then((res) => {
      refreshApps();
    });
  };

  const AppRow = ({ app }: { app: Application }) => {
    return (
      <tr>
        <td>
          <div className="d-flex align-items-center">
            <div className=""></div>
            <div className="">
              <h6 className="mb-1 font-14">{app.clientId}</h6>
            </div>
          </div>
        </td>
        <td>{app.displayName}</td>
        <td>{app.redirectUri}</td>
        <td>
          {app.postLogoutRedirectUri}
        </td>
        <td>
          <div className="row row-cols-auto g-3">
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => {setSelectedApp(app); $("#newAppModal").modal("show");}}
              >
                <i className="bx bx-pencil me-0"></i>
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => deleteApp(app.clientId)}
              >
                <i className="bx bx-trash me-0"></i>
              </button>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12 d-flex">
          <div className="card radius-10 w-100">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <h5 className="mb-1">Applications</h5>
                </div>
                <div className="ms-auto">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#newAppModal"
                    className="btn btn-outline-primary px-5"
                    onClick={() => setSelectedApp(undefined)}
                  >
                    <i className="bx bx-grid-alt mr-1"></i>New Application
                  </button>
                  <NewApplicationForm
                    onAddNewApp={handleSaveApp}
                    app={selectedApp}
                  ></NewApplicationForm>
                </div>
              </div>
              <div className="table-responsive mt-4">
                <table
                  className="table align-middle mb-0 table-hover"
                  id="Transaction-History"
                >
                  <thead className="table-light">
                    <tr>
                      <th>Client Id</th>
                      <th>Display Name</th>
                      <th>Redirect URL</th>
                      <th>Post Logout Redirect URL</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {apps.map((app) => (
                      <AppRow key={app.clientId} app={app}></AppRow>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <EditUser
        show={showEdit}
        onHide={() => setShowEdit(false)}
        roles={roles}
        user={selectedUser}
        onUpdateUser={handleSaveUser}
      ></EditUser> */}
    </>
  );
}
