import { Role } from "app/models/UserModel";
import { addRole, deleteRole, getRoles } from "app/services/UserAPI";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAuth } from "react-oidc-context";
import './Roles.scss';

export default function Roles() {
  const auth = useAuth();
  const [roles, setRoles] = React.useState<Array<Role>>([]);
  const [showNewRoleForm, setShowNewRoleForm] = useState<boolean>(false);
  const [removeRole, setRemoveRole] = useState<{show: boolean; role?: Role;}>({show: false});

  useEffect(() => {
    refreshRoles();
  }, []);

  const refreshRoles = () => {
    getRoles(auth.user?.access_token).then((res) => {
      setRoles(res.data);
    });
  };

  const addNewRole = (data: Role) => {
    addRole(auth?.user?.access_token, data).then((res) => {
        refreshRoles();
        setShowNewRoleForm(false);
    });
  };

  const deleteSelectedRole = () => {
    if (!removeRole.role) {
        return;
    }
    deleteRole(auth?.user?.access_token, removeRole.role.id).then((res) => {
        refreshRoles();
        setRemoveRole({ show:false })
    });
  };

  const RoleRow = ({ role }: { role: Role }) => {
    return (
      <tr>
        <td>
          <div className="d-flex align-items-center">
            <div className=""></div>
            <div className="ms-2">
              <h6 className="mb-1 font-14">{role.name}</h6>
              <p className="mb-0 font-13 text-secondary"></p>
            </div>
          </div>
        </td>
        <td>{role.countOfUsers}</td>
        <td>
          <div className="row row-cols-auto g-3">
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-danger"
                title="Delete role"
                onClick={() => setRemoveRole({ show: true, role })}
                disabled={!role.isEditable}
              >
                <i className="bx bx-trash me-0"></i>
              </button>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  const NewRole = () => {
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<Role>();

    return (
      <Modal
        show={showNewRoleForm}
        onHide={() => setShowNewRoleForm(false)}
        centered
      >
        <form onSubmit={handleSubmit(addNewRole)}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add new role
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-body">
              <div className="col-12 form-field-wrapper">
                <label htmlFor="inputRoleName" className="form-label">
                  Role
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputRoleName"
                  placeholder="Role"
                  {...register("name", {
                    required: "Role is required",
                  })}
                  maxLength={30}
                />
              </div>
              {errors.name && (
                <div role="alert" className="invalid-feedback">
                  {errors.name?.message as string}
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="reset"
              className="btn btn-secondary"
              onClick={() => setShowNewRoleForm(false)}
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  };

  const RemoveRoleConfirm = ({ role }: { role?: Role }) => {

    return (
      <Modal
        show={removeRole.show}
        onHide={() => setRemoveRole({ show:false })}
        centered
      >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Remove role
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to permanently remove the role - {role?.name}? </p>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="reset"
              className="btn btn-secondary"
              onClick={() => setRemoveRole({ show:false })}
            >
              No
            </button>
            <button type="button" className="btn btn-primary" onClick={() => deleteSelectedRole()}>
              Yes
            </button>
          </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12 d-flex">
          <div className="card radius-10 w-100">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <h5 className="mb-1">Application roles</h5>
                </div>
                <div className="ms-auto">
                  <button
                    type="button"
                    className="btn btn-outline-primary px-5"
                    onClick={() => setShowNewRoleForm(true)}
                  >
                    <i className="bx bx-user-plus mr-1"></i>New Role
                  </button>
                </div>
              </div>
              <div className="table-responsive mt-4">
                <table
                  className="table align-middle mb-0 table-hover"
                  id="Transaction-History"
                >
                  <thead className="table-light">
                    <tr>
                      <th>Role</th>
                      <th>Users</th>
                      <th className="roles-actions-col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roles.map((role) => (
                      <RoleRow key={role.id} role={role}></RoleRow>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewRole></NewRole>
      <RemoveRoleConfirm role={removeRole.role}></RemoveRoleConfirm>
    </>
  );
}
