import React, { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Role, User } from "../../../models/UserModel";
import "./NewUserForm.scss";
import { useAuth } from "react-oidc-context";
import { saveUser } from "../../../services/UserAPI";

type NewUserFormModel = {
  confirmPassword: string;
  roleNames: Array<string>;
} & User;

export default function NewUserForm(props: {
  roles: Array<Role>;
  onCreateNewUser: (user: User) => void;
}) {
  const auth = useAuth();
  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm<NewUserFormModel>({
    defaultValues: {
      firstName: "",
      lastName: "",
      indexNumber: "",
      email: "",
      password: "",
      confirmPassword: "",
      roles: [],
      roleNames: []
    },
  });

  const onSubmitNewUser = (data: NewUserFormModel) => {
    data.roles = data.roleNames.map(role => ({ name: role }));
    saveUser(auth?.user?.access_token, data).then((res) => {
      $("#newUserModal").modal("hide");
      reset();
      props.onCreateNewUser(res.data);
    });
  };

  return (
    <div
      className="modal fade"
      id="newUserModal"
      tabIndex={-1}
      aria-labelledby="newUserModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <form onSubmit={handleSubmit(onSubmitNewUser)}>
            <div className="modal-header">
              <h5 className="modal-title" id="newUserModalLabel">
                Add new user
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-body">
                <div className="col-12 form-field-wrapper">
                  <label htmlFor="inputFirstName" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputFirstName"
                    placeholder="First Name"
                    {...register("firstName", {
                      required: "First Name is required",
                    })}
                    maxLength={30}
                  />
                </div>
                {errors.firstName && (
                  <div role="alert" className="invalid-feedback">
                    {errors.firstName?.message as string}
                  </div>
                )}
                <div className="col-12 form-field-wrapper">
                  <label htmlFor="inputLastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputLastName"
                    placeholder="Last Name"
                    {...register("lastName", {
                      required: "Last Name is required",
                    })}
                    maxLength={30}
                  />
                </div>
                {errors.lastName && (
                  <div role="alert" className="invalid-feedback">
                    {errors.lastName?.message as string}
                  </div>
                )}
                <div className="col-12 form-field-wrapper">
                  <label htmlFor="inputindexNumber" className="form-label">
                    Index Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputIndexNumber"
                    placeholder="Index Number"
                    {...register("indexNumber", {
                      required: "IndexNumber is required",
                    })}
                    maxLength={30}
                  />
                </div>
                {errors.indexNumber && (
                  <div role="alert" className="invalid-feedback">
                    {errors.indexNumber?.message as string}
                  </div>
                )}
                <div className="col-12 form-field-wrapper">
                  <label htmlFor="inputEmailAddress" className="form-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmailAddress"
                    placeholder="example@user.com"
                    {...register("email", { required: "Email is required" })}
                  />
                  {errors.email && (
                    <div role="alert" className="invalid-feedback">
                      {errors.email?.message as string}
                    </div>
                  )}
                </div>
                <div className="col-12 form-field-wrapper">
                  <label htmlFor="inputChoosePassword" className="form-label">
                    Password
                  </label>
                  <div className="input-group" id="show_hide_password">
                    <input
                      type="password"
                      className="form-control border-end-0"
                      id="inputChoosePassword"
                      placeholder="Enter Password"
                      {...register("password", {
                        required: "Password is required",
                        pattern: {
                          value:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                          message:
                            "Invalid Password (Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character)",
                        },
                      })}
                    />
                    <a
                      href="javascript:void(0);"
                      className="input-group-text bg-transparent"
                    >
                      <i className="bx bx-hide"></i>
                    </a>
                  </div>
                  {errors.password && (
                    <div role="alert" className="invalid-feedback">
                      {errors.password?.message as string}
                    </div>
                  )}
                </div>
                <div className="col-12 form-field-wrapper">
                  <label htmlFor="inputConfirmPassword" className="form-label">
                    Confirm Password
                  </label>
                  <div className="input-group" id="show_hide_password">
                    <input
                      type="password"
                      className="form-control"
                      id="inputConfirmPassword"
                      placeholder="Renter Password"
                      {...register("confirmPassword", {
                        required: "Confirm Password is required",
                        validate: {
                          matchesPreviousPassword: (value) => {
                            const { password } = getValues();
                            return (
                              password === value || "Passwords should match!"
                            );
                          },
                        },
                      })}
                    />
                  </div>
                  {errors.confirmPassword && (
                    <div role="alert" className="invalid-feedback">
                      {errors.confirmPassword?.message as string}
                    </div>
                  )}
                </div>

                <div className="col-12 form-field-wrapper">
                  <label className="form-label">Roles</label>
                  <div className="input-group" id="show_hide_password">
                    {props.roles.map((role) => (
                      <div className="form-check" key={role.id}>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`checkbox${role.name}`}
                          value={role.name}
                          {...register("roleNames")}
                          disabled={!role.isEditable}
                        />
                        <label
                          className="form-check-label custom-checkbox-label"
                          htmlFor={`checkbox${role.name}`}
                        >
                          {role.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="reset"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
