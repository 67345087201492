import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Role, User } from "../../models/UserModel";
import { getRoles, getUsers, lockOutUser, unlockUser } from "../../services/UserAPI";
import "./Users.scss";
import NewUserForm from "./NewUserForm/NewUserForm";
import EditUser from "./EditUser/EditUser";

export function Users() {
  const auth = useAuth();

  const [users, setUsers] = React.useState<Array<User>>([]);
  const [roles, setRoles] = React.useState<Array<Role>>([]);
  const [selectedUser, setSelectedUser] = React.useState<User>();
  const [showEdit, setShowEdit] = React.useState<boolean>(false);

  useEffect(() => {
    refreshUsers();
  }, []);

  useEffect(() => {
    getRoles(auth.user?.access_token).then((res) => {
      setRoles(res.data);
    });
  }, []);

  const refreshUsers = () => {
    getUsers(auth.user?.access_token).then((res) => {
      setUsers(res.data);
    });
  };

  const lock = (user: User) => {
    lockOutUser(auth.user?.access_token, user.id).then(() => {
      refreshUsers();
    });
  };

  const unlock = (user: User) => {
    unlockUser(auth.user?.access_token, user.id).then(() => {
      refreshUsers();
    });
  };

  const handleSaveUser = () => {
    refreshUsers();
  };

  const UserRow = ({ user }: { user: User }) => {
    return (
      <tr>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>
          <div className="d-flex align-items-center">
            <div className=""></div>
            <div className="ms-2">
              <h6 className="mb-1 font-14">{user.indexNumber}</h6>
              <p className="mb-0 font-13 text-secondary"></p>
            </div>
          </div>
        </td>
        <td>{user.email}</td>
        <td>{user.roles.map(role => role.name).join(", ")}</td>
        <td>
          {!user.lockoutEnd || user.lockoutEnd < new Date() ? (
            <div className="badge rounded-pill bg-success w-100">Active</div>
          ) : (
            <div className="badge rounded-pill bg-danger w-100">Locked out</div>
          )}
        </td>
        <td>
          <div className="row row-cols-auto g-3">
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => {setSelectedUser(user); setShowEdit(true);}}
              >
                <i className="bx bx-pencil me-0"></i>
              </button>
            </div>
            <div className="col">
              {!user.lockoutEnd || user.lockoutEnd < new Date() ? (
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={() => lock(user)}
                >
                  <i className="bx bx-lock me-0"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-outline-info"
                  onClick={() => unlock(user)}
                >
                  <i className="bx bx-lock-open me-0"></i>
                </button>
              )}
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12 d-flex">
          <div className="card radius-10 w-100">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <h5 className="mb-1">Application users</h5>
                </div>
                <div className="ms-auto">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#newUserModal"
                    className="btn btn-outline-primary px-5"
                  >
                    <i className="bx bx-user-plus mr-1"></i>New User
                  </button>
                  <NewUserForm
                    roles={roles}
                    onCreateNewUser={handleSaveUser}
                  ></NewUserForm>
                </div>
              </div>
              <div className="table-responsive mt-4">
                <table
                  className="table align-middle mb-0 table-hover"
                  id="Transaction-History"
                >
                  <thead className="table-light">
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Index Number</th>
                      <th>Email</th>
                      <th>Roles</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <UserRow key={user.id} user={user}></UserRow>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditUser
        show={showEdit}
        onHide={() => setShowEdit(false)}
        roles={roles}
        user={selectedUser}
        onUpdateUser={handleSaveUser}
      ></EditUser>
    </>
  );
}
