import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import store from "core/store";
import { Provider } from "react-redux";
import { AuthProvider } from "react-oidc-context";
import { AuthConfig } from "core/auth";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Logout } from "app/logout/Logout";
import { Private } from "app/private/Private";
import { Users } from "app/private/Users/Users";
import Dashboard from "app/private/Dashboard/Dashboard";
import Applications from "app/private/Applications/Applications";
import Roles from "app/private/Roles/Roles";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Private />,
        children: [
          {
            path: "/",
            element: <Applications />
          },
          {
            path: "users",
            element: <Users />
          },
          {
            path: "roles",
            element: <Roles />
          }
        ]
      },
      {
        path: "logout",
        element: <Logout />
      }
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider {...AuthConfig}>
      {/* <Provider store={ store }> */}
        <RouterProvider router={router} />
      {/* </Provider> */}
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
