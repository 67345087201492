import React from "react";
import logo from "assets/logo.png";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { useAuth } from "react-oidc-context";
import "./header.scss";

function Header() {
  const auth = useAuth();

  function logout() {
    auth.signoutRedirect();
  }

  return (
    <header>
      <div className="topbar d-flex align-items-center">
        <nav className="navbar navbar-expand">
          <div className="mobile-toggle-menu">
            <i className="bx bx-menu"></i>
          </div>
          <div className="topbar-logo-header">
            <div className="">
              <h4 className="logo-text">User Manager</h4>
            </div>
          </div>
          {auth.isAuthenticated && (
            <div className="user-box dropdown ms-auto">
              <a
                className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="user-img">
                  <UserIcon />
                </div>
                <div className="user-info ps-3">
                  <p className="user-name mb-0">{auth.user?.profile.name}</p>
                  {/* <p className="designattion mb-0">Admin</p> */}
                </div>
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
                <li onClick={logout}>
                  <a className="dropdown-item" href="javascript:void(0);">
                    <i className="bx bx-log-out-circle"></i>
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
}

export default Header;
