import { Role, User } from "../../../models/UserModel";
import { useAuth } from "react-oidc-context";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { updateUser } from "../../../services/UserAPI";
import { useEffect, useMemo } from "react";

interface EditUserForm extends Partial<User> {
  roleNames: Array<string>;
}

export default function EditUser(props: {
  roles: Array<Role>;
  user?: User;
  onUpdateUser: (user: User) => void;
  show: boolean;
  onHide: () => void;
}) {
  const auth = useAuth();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<EditUserForm>({
    defaultValues: useMemo(() => {
      return {
        roles: props.user?.roles ?? [],
        roleNames: props.user?.roles.map((role) => role.name) ?? [],
        firstName: props.user?.firstName,
        lastName: props.user?.lastName,
        indexNumber: props.user?.indexNumber
      };
    }, [props]),
  });

  // useEffect(() => {
  //   setValue("roles", props.user?.roles ?? []);
  //   setValue("roleNames", props.user?.roles.map((role) => role.name) ?? []);
  //   setValue("firstName", props.user?.firstName);
  //   setValue("lastName", props.user?.lastName);
  //   setValue("indexNumber", props.user?.indexNumber);
  // });
  useEffect(() => {
    reset({
      roles: props.user?.roles ?? [],
      roleNames: props.user?.roles.map((role) => role.name) ?? [],
      firstName: props.user?.firstName,
      lastName: props.user?.lastName,
      indexNumber: props.user?.indexNumber
    });
  }, [props]);

  const onUpdateUser = (data: EditUserForm) => {
    if (!props.user) {
      props.onHide();
      return;
    }
    const newUserObj: User = {
      ...props.user,
      firstName: data.firstName ?? "",
      lastName: data.lastName ?? "",
      indexNumber: data.indexNumber ?? "",
      ...{ roles: data.roleNames.map((role) => ({ name: role })) },
    };
    updateUser(auth?.user?.access_token, newUserObj).then((res) => {
      props.onHide();
      reset();
      props.onUpdateUser(res.data);
    });
  };

  return (
    <Modal show={props.show} onHide={props.onHide} centered>
      <form onSubmit={handleSubmit(onUpdateUser)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit - {props.user?.firstName} {props.user?.lastName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-body">
            <div className="col-12 form-field-wrapper">
              <label htmlFor="inputFirstName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="inputFirstName"
                placeholder="First Name"
                {...register("firstName", {
                  required: "First Name is required",
                })}
                maxLength={30}
              />
            </div>
            {errors.firstName && (
              <div role="alert" className="invalid-feedback">
                {errors.firstName?.message as string}
              </div>
            )}
            <div className="col-12 form-field-wrapper">
              <label htmlFor="inputLastName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                id="inputLastName"
                placeholder="Last Name"
                {...register("lastName", {
                  required: "Last Name is required",
                })}
                maxLength={30}
              />
            </div>
            {errors.lastName && (
              <div role="alert" className="invalid-feedback">
                {errors.lastName?.message as string}
              </div>
            )}
            <div className="col-12 form-field-wrapper">
              <label htmlFor="inputIndexNumber" className="form-label">
                Index Number
              </label>
              <input
                type="text"
                className="form-control"
                id="inputIndexNumber"
                placeholder="Index Number"
                {...register("indexNumber", {
                  required: "IndexNumber is required",
                })}
                maxLength={30}
              />
            </div>
            {errors.indexNumber && (
              <div role="alert" className="invalid-feedback">
                {errors.indexNumber?.message as string}
              </div>
            )}
            <div className="col-12">
              <label className="form-label">Roles</label>
              <div className="input-group" id="show_hide_password">
                {props.roles.map((role) => (
                  <div className="form-check" key={`edit${role.id}`}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`checkboxEdit${role.name}`}
                      value={role.name}
                      {...register("roleNames")}
                      disabled={!role.isEditable}
                    />
                    <label
                      className="form-check-label custom-checkbox-label"
                      htmlFor={`checkboxEdit${role.name}`}
                    >
                      {role.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="reset"
            className="btn btn-secondary"
            onClick={props.onHide}
          >
            Close
          </button>
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
