import { useAuth } from "react-oidc-context";

export function Logout() {
  const auth = useAuth();

  const login = () => {
    auth.signinRedirect();
  };

  return (
    <>
      <h2>You have been logged out...</h2>
      <br />
      <button type="button" className="btn btn-primary px-5" onClick={login}>
        Login
      </button>
    </>
  );
}
