import { useForm } from "react-hook-form";
import { useAuth } from "react-oidc-context";
import { Application } from "app/models/ApplicationModel";
import { addApplication, updateApplication } from "app/services/ApplicationsAPI";
import { useEffect } from "react";

export default function NewApplicationForm(props: {
    app?: Application,
    onAddNewApp: (application: Application) => void;
}) {
    const auth = useAuth();
    const urlRegex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)|https?:\/\/(localhost)+(:[0-9]+)?\/?([.\w-\/]*)*$/;
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setValue
    } = useForm<Application>();

    useEffect(() => {
        reset();
        if (props.app) {
            setValue("clientId", props.app.clientId);
            setValue("displayName", props.app.displayName);
            setValue("redirectUri", props.app.redirectUri);
            setValue("postLogoutRedirectUri", props.app.postLogoutRedirectUri);
        }
    }, [props.app])

    const onSubmitNewApp = (data: Application) => {
        if (!props.app) {

            addApplication(auth?.user?.access_token, data).then((res) => {
                $("#newAppModal").modal("hide");
                reset();
                props.onAddNewApp(res.data);
            }).catch(error => { });

        } else {

            updateApplication(auth?.user?.access_token, data).then((res) => {
                $("#newAppModal").modal("hide");
                reset();
                props.onAddNewApp(res.data);
            });

        }

    };

    return (
        <div
            className="modal fade"
            id="newAppModal"
            tabIndex={-1}
            aria-labelledby="newAppAppLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <form onSubmit={handleSubmit(onSubmitNewApp)}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="newAppModalLabel">
                                {props.app ? `Update ${props.app.displayName}` : "Add new application"}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-body">
                                <div className="col-12 form-field-wrapper">
                                    <label htmlFor="inputClientId" className="form-label">
                                        Application Id
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputClientId"
                                        disabled={!!props.app}
                                        placeholder="Application Id"
                                        {...register("clientId", {
                                            required: "Application Id is required",
                                        })}
                                        maxLength={30}
                                    />
                                    {errors.clientId && (
                                        <div role="alert" className="invalid-feedback">
                                            {errors.clientId?.message as string}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12 form-field-wrapper">
                                    <label htmlFor="inputDisplayName" className="form-label">
                                        Display Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputDisplayName"
                                        placeholder="Display name"
                                        {...register("displayName", {
                                            required: "Display name is required",
                                        })}
                                    />
                                    {errors.displayName && (
                                        <div role="alert" className="invalid-feedback">
                                            {errors.displayName?.message as string}
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 form-field-wrapper">
                                    <label htmlFor="inputRedirectUri" className="form-label">
                                        Redirect URL
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputRedirectUri"
                                        placeholder="Redirect URL"
                                        {...register("redirectUri", {
                                            required: "Redirect URL is required",
                                            pattern: {
                                                value: urlRegex,
                                                message:
                                                    "Invalid URL(eg. https://www.test.com)",
                                            }
                                        })}
                                    />
                                    {errors.redirectUri && (
                                        <div role="alert" className="invalid-feedback">
                                            {errors.redirectUri?.message as string}
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 form-field-wrapper">
                                    <label
                                        htmlFor="inputLogoutRedirectUri"
                                        className="form-label"
                                    >
                                        Post Logout Redirect URL
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputLogoutRedirectUri"
                                        placeholder="Redirect URL"
                                        {...register("postLogoutRedirectUri", {
                                            required: "Post logout redirect URL is required",
                                            pattern: {
                                                value: urlRegex,
                                                message:
                                                    "Invalid URL(eg. https://www.test.com)",
                                            }
                                        })}
                                    />
                                    {errors.postLogoutRedirectUri && (
                                        <div role="alert" className="invalid-feedback">
                                            {errors.postLogoutRedirectUri?.message as string}
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 form-field-wrapper">
                                    <label htmlFor="inputClientSecret" className="form-label">
                                        Application Password
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control border-end-0"
                                            id="inputClientSecret"
                                            placeholder="eg. de2f0732-ff09-4aec-b8fd-2c7b7e7ed6b8 "
                                            {...register("clientSecret", {
                                                required: !props.app && "Password is required",
                                                minLength: {
                                                    value: 8,
                                                    message:
                                                        "Invalid Password (Minimum eight characters)",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors.clientSecret && (
                                        <div role="alert" className="invalid-feedback">
                                            {errors.clientSecret?.message as string}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="reset"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                                {props.app ? "Update" : "Save"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
