import axios from "axios";
import { AuthConfig } from "core/auth";
import { UserManager } from "oidc-client-ts";

const apiHandler = (token?: string) => {
    const axiosInstance = axios.create();
    const mgr = new UserManager(AuthConfig);

    axiosInstance.interceptors.request.use(
        function (config) {
            config.headers['Authorization'] = `Bearer ${token}`;
            return config;
        },
        function (error) {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (error.response.status === 401) {
            mgr.signinRedirect();
            return;
        } else if (error.response.status === 403) {
            alert("You are not authorized to use this application")
            mgr.signoutRedirect();
            return;
        }
        if (error?.response?.data?.message) {
            alert(error?.response?.data?.message);
        }
        return Promise.reject(error);
    })

    return axiosInstance;
}



export default apiHandler;
